.dish-card-container {
    box-shadow: 0px 2.6766px 6.69151px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: white;
    margin-right: 35px;
    margin-bottom: 40px;
    max-width: 254px;
}

.dish-card-footer{
    display: column;
    padding: 13px
}

.dish-card-footer-actions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
}

.dish-card-picture{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    max-width: 255px;
    max-height: 149px;
    min-height: 149px;
    min-width: 255px;
    object-fit: cover;
}

.dish-card-name {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #061A42;
    word-wrap: break-word;
}

.dish-card-price{
    color: #97A5C2;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
}

.dish-card-toggles {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
